.ng-select-container{
  border: 1px solid #e5e6e7 !important;
  border-radius: 0px !important;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon:hover {
  background-color: var(--cor-danger) !important;
  color: #fff !important;
  padding: 1px 5px !important;
  border-radius: 10px 0px 0px 10px !important;
}

.ng-select .ng-clear-wrapper:hover .ng-clear {
  color: var(--cor-danger);
}

.ng-select.ng-select-single .ng-select-container {
  height: 45px;
}

.ng-select .ng-select-container {
  background: var(--input-background) !important;
  color: var(--text-cor-padrao) !important;
  border: var(--border-padrao) !important;
  border-radius: .50rem !important;
  padding: 4px !important;
}

.ng-value-container {
  padding: 6px 6px !important;
}

.ng-select .ng-select-container .ng-value-container .ng-input>input{
  cursor: pointer !important;
  border: 0 none !important;
  box-shadow: none !important;
  background: transparent !important;
  color: var(--text-cor-padrao) !important;
}

.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
  top: 10px;
}

.ng-placeholder {
  min-height: 26px !important;
  padding: 7px 3px !important;
  margin: 0px !important;
  display: flex;
  align-items: center;
  color: var(--text-cor-padrao) !important;
}

.ng-dropdown-panel {
  z-index: 1060 !important;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option{
  background: var(--linear-gradient-0deg);
  color: var(--text-cor-padrao);
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked{
  background: var(--cor-secundaria);
  color: var(--cor-branco);
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.left {
  border-right: var(--border-padrao);
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value{
  background: var(--cor-primaria) !important;
  color: #fff !important;
  margin: 3px 8px 3px 4px !important;
  border-radius: 10px 5px 5px 10px;
  font-size: 13px !important;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected, .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked{
  background: var(--cor-primaria);
  color: #fff;
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected .ng-option-label, .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked .ng-option-label{
  font-weight: initial !important;
}

.ng-dropdown-panel-items.scroll-host {
  text-align: left;
}

.ng-select {
    width: 100% !important;
}


.ng-dropdown-panel.ng-select-bottom{
  border: 1px solid var(--cor-secundaria);
}
