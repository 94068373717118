.notificacao-badge-content{
  z-index: 20;
  /* position: fixed;
  right: 65px;
  top: 15px; */
  font-size: 20px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  color: var(--text-cor-padrao);
  cursor: auto;
}

.notificacao-badge-content-dropdown-menu{
  min-width: 320px;
  background: var(--linear-gradient-0deg);
  border: var(--border-padrao);
  border-radius: 5px;
  box-shadow: var(--box-shadow_p);
  padding: 15px !important;
  position: absolute;
  inset: 0px auto auto 0px;
  margin: 0px;
}

.notificacao-badge-content-title-nao-lidas{
  border-bottom: 1px solid var(--border-cor);
  display: flex;
  margin-bottom: 5px;
  justify-content: space-between;
  padding: 7px 0px;
  color: var(--text-cor-padrao);
  font-weight: 600;
}

.notification-item-nao-lido{
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0px 0px;
  padding: 5px;
  border-radius: 9px;
  cursor: pointer;
}

.notificacao-badge-content-title-lidas{
  border-bottom: 1px solid var(--border-cor);
  display: flex;
  margin-bottom: 5px;
  justify-content: space-between;
  padding: 7px 0px;
  color: var(--text-cor-padrao);
  font-weight: 600;
}

.notification-item-lido{
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0px 0px;
  padding: 5px;
  border-radius: 9px;
}

.notification-item-nao-lido-span,
.notification-item-lido-span{
  font-size: 10px;
  text-align: right;
  /* border-top: 1px solid var(--border-cor); */
  margin-top: 5px;
  display: flex;
  justify-content: space-between;
  /* background: var(--background); */
  background: transparent;
  padding: 0px 7px;
  border-radius: 5px;
  color: var(--text-cor-padrao);
}

.notificacao-badge-content-dropdown-menu .rounded-pill{
  font-size: 11px;
  display: flex;
  align-items: center;
}
