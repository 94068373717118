/* NGX LOADING BAR */

.content-ngx-loading-bar{
  z-index: 999 !important;
  position: fixed !important;
  width: calc(100% - 260px);
}

.sidebar.close ~ .home-section .content-ngx-loading-bar{
  width: calc(100% - 78px);
}

.ngx-spinner{
  position: absolute !important;
  top: 7px !important;
  left: 5px !important;
}

.ngx-spinner .ngx-spinner-icon{
  width: 10px !important;
  height: 10px !important;
  border: solid 1px;
  display: none !important;
}

/* Adicione uma transição suave para a cor de fundo */
.bg-transition {
  transition: background-color 3s ease;
  animation: animateBackground 6s infinite;

}

/* Adicione uma animação de fundo para suavizar a transição de cor */
.animate-bg {
  animation: animateBackground 6s infinite;
}

@keyframes animateBackground {
  0% {
    background-color: var(--cor-primaria);
  }
  50% {
    background-color: var(--cor-sucesso);
  }
  100% {
    background-color: var(--cor-primaria);
  }
}


.error-ngx-bar{
  background: var(--cor-danger) !important;
  color: var(--cor-danger) !important;
}
