/* TOAST */

.toast-container {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 3000;
}

.toast {
  /* padding: 15px 10px; */
  color: #fff;
  border-radius: 4px;
  opacity: 1 !important;
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 60px;
}

.toast-content{
  display: flex;
  align-items: center;
  padding: 15px 10px;
}

.toast.bg-success {
  background-color: var(--cor-sucesso) !important;
}

.toast.bg-danger {
  background-color: var(--cor-danger) !important;
}

.toast.bg-info{
  background-color: var(--cor-info) !important;
}

.toast-progress-bar {
  height: 4px;
  background-color: #0d1b274d;
  animation: toast-progress-animation linear infinite;
  position: relative;
  bottom: 0px;
  border-radius: 0px 0px 5px 5px;
}

@keyframes toast-progress-animation {
  0% {
    width: 0; /* Largura inicial da barra de progresso */
  }
  100% {
    width: 100%; /* Largura final da barra de progresso */
  }
}

.toast-close-button{
  position: absolute;
  right: 0;
  width: 30px;
  height: 30px;
  border: 0;
  background: transparent;
  font-size: 11px;
  color: var(--cor-branco)
}
