/* Imports */
@import "~@ng-select/ng-select/themes/default.theme.css";

@import url('https://fonts.googleapis.com/css2?family=Gabarito:wght@400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@import "./assets/css/menu-home.css";
@import "./assets/css/utilidades.css";
@import "./assets/css/material-custom.css";
@import "./assets/css/calendario.css";
@import "./assets/css/theme-custom-datepicker.css";
@import "./assets/css/ng-select.css";
@import "./assets/css/toast.css";
@import "./assets/css/ng-loading-bar.css";
@import "./assets/css/notificacao-badge.css";

:root {
  --cor-primaria: #005db1;
  --cor-secundaria: #2f3e4f;
  --cor-destaque: #ddc312;
  --cor-alerta: #ddc312;
  --cor-danger: #ae0e0e;
  --cor-sucesso: #0eae1c;
  --cor-info: #1a5597;
  --cor-branco: #fff;
  --cor-black: #17212f;
  --hover-cor-primaria: #ebeff3;
  --hr-padrao: linear-gradient(65deg,#17212f 60%,#17212f 100%);
  --text-cor-padrao: #e7edf3;
  --text-cor-sub: #dfdfdf;
  --text-background-clip: -webkit-linear-gradient(30deg,#ebeff3,#fff);
  --background-clip: linear-gradient(30deg,#2a8deb,#2a8deb);
  --box-radial-gradient: linear-gradient(60deg,#272a34 60%,#282b34 100%);
  --box-shadow: rgb(0 0 0 / 5%) 0px 6px 12px;
  --box-shadow_p: rgb(0 0 0 / 5%) 0px 6px 12px;
  --box-shadow_s: rgb(0 0 0 / 5%) 0px 6px 12px;
  --border-padrao: 1.5px solid #17212f;
  --border-cor: #17212f;
  --linear-gradient-0deg: linear-gradient(0deg,#1f2d40 0%,#1f2d40 100%);
  --linear-gradient--60deg: linear-gradient(-60deg,#1f2d40 0%,#1f2d40 100%);
  --radial-gradient-1: linear-gradient(65deg, #005db1,#005db1);
  --background: #17212f;
  --background-secundario: #2a8deb;
  --background-p-b: #1f2d40;
  --webkit-box-shadow: 0 0 0 1000px #17212f inset;
  --pulsate-background: linear-gradient(65deg, #17212f, #1f2d40, #17212f, #1c1f23);
  --pulsate-background-input: linear-gradient(65deg, #17212f, #1f2d40, #17212f, #1c1f23);
  --input-background: #17212f;
  --button-background: #1f2d40;
  --lw-cor-p: #ff6400;
  --lw-cor-s: #17212f;
  --lw-cor-1: #BF303C;
  --lw-cor-2: #17212f;
  --lw-cor-3: #F2913D;
  --lw-cor-4: #ff6400;
  --lw-cor-5: #ebeff3;
  --lw-cor-6: #1f2d40;
}

:root.light {
  --cor-primaria: #0e68ff;
  --cor-secundaria: #2f3e4f;
  --cor-destaque: #ffc107;
  --cor-alerta: #ffc107;
  --cor-danger: #d6050b;
  --cor-sucesso: #38a713;
  --cor-info: #1a68bf;
  --cor-branco: #fff;
  --cor-black: #17212f;
  --hover-cor-primaria: #fff;
  --hr-padrao: linear-gradient(65deg,#ececec,#ececec);
  --text-cor-padrao: #1a1e23;
  --text-cor-sub: #444444;
  --text-background-clip: -webkit-linear-gradient(30deg,#0e68ff,#0e68ff);
  --background-clip: linear-gradient(30deg,#2a8deb,#2a8deb);
  --box-radial-gradient: radial-gradient(#ffffff,#fafafa);
  --box-shadow: rgb(0 0 0 / 5%) 0px 6px 12px;
  --box-shadow_p: rgb(0 0 0 / 5%) 0px 6px 12px;
  --box-shadow_s: rgb(0 0 0 / 5%) 0px 6px 12px;
  --border-padrao: 1.5px solid #ececec;
  --border-cor: #ececec;
  --linear-gradient-0deg: linear-gradient(0deg,#ffffff 0%,#ffffff 100%);
  --linear-gradient--60deg: linear-gradient(-60deg,#ffffff,#ffffff);
  --radial-gradient-1: linear-gradient(65deg, #0e68ff,#0e68ff);
  --background: #ebeff3; /* f3f6fc */
  --background-secundario: #2a8deb;
  --background-p-b: #fff;
  --webkit-box-shadow: 0 0 0 1000px #fafafa inset;
  --pulsate-background: linear-gradient(65deg, #ececec, #ffffff, #ececec, #ffffff);
  --pulsate-background-input: linear-gradient(65deg, #ececec, #ffffff, #ececec, #ffffff);
  --input-background: #f5f5f5;
  --button-background: #ffffff;
  --lw-cor-p: #ff6400;
  --lw-cor-s: #17212f;
  --lw-cor-1: #BF303C;
  --lw-cor-2: #17212f;
  --lw-cor-3: #F2913D;
  --lw-cor-4: #ff6400;
  --lw-cor-5: #ebeff3;
  --lw-cor-6: #1f2d40;
}

* {
	margin: 0px;
	padding: 0px;
	box-sizing: border-box;
  /* font-family: 'Gabarito', 'Roboto', cursive !important; */
  font-family: Segoe UI,Helvetica Neue,Helvetica,Lucida Grande,Arial,Ubuntu,Cantarell,Fira Sans,sans-serif;
}

.roulette-number {
  /* animation: fadeIn 0s ease-in-out forwards; */
  opacity: 1;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

svg g text, svg text{
  fill: var(--text-cor-padrao);
}

span.apexcharts-legend-marker{
  height: 14px !important;
  width: 14px !important;
  border-radius: 4px !important;
}

span.apexcharts-legend-text {
  color: var(--text-cor-padrao) !important;
  font-size: 14px !important;
  margin-left: -6px !important;
  margin-right: 3px !important;
  padding: 10px;
  font-weight: 500 !important;
  font-family: Segoe UI,Helvetica Neue,Helvetica,Lucida Grande,Arial,Ubuntu,Cantarell,Fira Sans,sans-serif !important;
}

.apexcharts-toolbar {
  z-index: 9 !important;
  top: -6px !important;
}

.apexcharts-gridline {
  /* stroke: var(--cor-secundaria); */
  stroke: var(--background);
}

.apexcharts-menu-icon, .apexcharts-pan-icon, .apexcharts-reset-icon, .apexcharts-selection-icon, .apexcharts-toolbar-custom-icon, .apexcharts-zoom-icon, .apexcharts-zoomin-icon, .apexcharts-zoomout-icon {
  cursor: pointer;
  width: 25px !important;
  /* height: 25px; */
  line-height: 24px;
  color: var(--text-cor-padrao);
  text-align: center;
  transform: scale(.75) !important;
}



.wrapper-progressBarAgendamento {
  width: 100%
}

.progressBarAgendamento {
display: flex !important;
}

.progressBarAgendamento li {
  list-style-type: none;
  float: left;
  width: 100%;
  position: relative;
  text-align: center;
  font-size: 10px;
}

.progressBarAgendamento li span {
  margin: 0px 3px;
  text-align: center;
  display: flex;
  border-radius: 10px;
  justify-content: center;
  font-size: 12px;
  color: var(--text-cor-padrao);
  align-items: center;
  flex-direction: column-reverse;
  flex-wrap: wrap;
  /* background: var(--background); */
  padding: 0px 10px;
}

.progressBarAgendamento li:before {
  content: " ";
  line-height: 18px;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  border: 1px solid var(--background);
  display: block;
  text-align: center;
  margin: 0px auto 0px;
  background-color: var(--background);
  position: relative;
  z-index: 1;
}

.progressBarAgendamento li:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: var(--background);
  top: 10px;
  left: -50%;
  z-index: 0;
}

.progressBarAgendamento li:first-child:after {
  content: none;
}

.progressBarAgendamento li.active span {
  color: var(--text-cor-padrao);
}

.progressBarAgendamento li.active:before {
  border-color: var(--cor-sucesso);
  background-color: var(--cor-sucesso);
  content: "\2713";
  color: var(--cor-branco);
  z-index: 1;
}

.progressBarAgendamento li.active:after {
  background-color: var(--cor-sucesso);
  z-index: 0;
}

.progressBarAgendamento li.activeDanger:before {
  border-color: var(--cor-danger);
  background-color: var(--cor-danger);
  content: "\2716";
  color: var(--cor-branco);
  z-index: 1;
}

.progressBarAgendamento li.activeDanger:after {
  background-color: var(--cor-danger);
  background: linear-gradient(90deg, var(--cor-sucesso) 50%, var(--cor-danger) 50%);
  z-index: 0;
}

.progressBarAgendamento li.done span {
color: var(--text-cor-padrao) !important;
}

.progressBarAgendamento li.done:before {
border: 5px solid var(--cor-sucesso);
background-color: var(--background);
content: "";
color:  var(--cor-sucesso);
}

.progressBarAgendamento li.done:after {
background-color: var(--cor-sucesso);

}

@media (max-width: 576px){
  .reset-padding-mobile {
    padding-right: 0;
    padding-left: 0;
  }

  .box-gen-card-min{
    min-height: 85px !important;
    border-radius: 5px !important;
    display: flex !important;
    align-items: center !important;
    flex-direction: column !important;
    flex-wrap: wrap !important;
    justify-content: space-around !important;
    background: var(--background) !important;
    box-shadow: var(--box-shadow_s);
    border: var(--border-padrao) !important;
    position: relative !important;
  }

  .box-gen-card-min-title{
    width: 100% !important;
    text-align: center !important;
    margin-bottom: 10px !important;
    padding-right: 0px !important;
    color: var(--text-cor-padrao) !important;
    font-weight: bold !important;
    font-size: 10px;
    z-index: 1;
  }

  .box-gen-card-min-info{
    width: 100% !important;
    text-align: center !important;
    font-size: 30px !important;
    margin-top: 10px !important;
    padding-left: 0px !important;
    color: var(--text-cor-padrao) !important;
    font-weight: bold !important;
    z-index: 1;
  }

}


@media (min-width: 1400px){
  .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
    max-width: 90%;
  }
}

/* ELEMENTOS LAYOUT */

.box-gen-card{
  background: var(--linear-gradient-0deg);
  border: var(--border-padrao);
  border-radius: 5px;
  padding: 15px 15px !important;
  box-shadow: var(--box-shadow_p);
  width: 100%;
  display: flex;
  /* overflow: auto; */
  flex-direction: column;
  position: relative;
  overflow: inherit;
}

.box-gen-card-min{
  min-height: 75px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
  background: var(--background);
  box-shadow: var(--box-shadow_s);
  border: var(--border-padrao);
  position: relative;
}

.box-gen-card-min-title{
  /* width: 100%; */
  text-align: right;
  padding-right: 10%;
  color: var(--text-cor-padrao);
  font-weight: bold;
  z-index: 1;
}

.box-gen-card-min-info{
  width: 100%;
  text-align: left;
  font-size: 30px;
  padding-left: 10%;
  color: var(--text-cor-padrao);
  font-weight: bold;
  z-index: 1;
  cursor: pointer;
}

.box-gen-card-list{
  margin: 15px 0px 0px;
  padding: 10px 15px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  border: var(--border-padrao);
  border-radius: 8px;
  color: var(--text-cor-padrao);
  flex-direction: column;
  position: relative;
  text-align: left;
}

.box-gen-card-h3{
  height: 100%;
  display: flex;
  align-items: center;
  min-height: 40px;
}

.box-gen-card-h3-span{
  font-weight: 600;
  padding: 0px 5px;
}

.box-gen-card-top{
  align-items: center !important;
  flex-direction: row !important;
  flex-wrap: wrap !important;
  justify-content: space-between !important;
}

.gen-card{
  margin: 0px 0px 10px;
  padding: 10px 15px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  border: var(--border-padrao);
  border-radius: 8px;
  color: var(--text-cor-padrao);
  flex-direction: column;
}

.gen-card-title{
  padding: 0 !important;
  margin: 0 !important;
  font-weight: 500;
  text-align: left !important;
}

.gen-card-info{
  padding: 0 !important;
  margin: 0 !important;
  /* font-style: italic; */
  text-align: left !important;
}

.gen-card-ul{
  text-align: left;
  border: none;
  padding: 0px 0px 5px 0px;
  background: var(--linear-gradient-0deg);
  color: var(--text-cor-padrao);
}

.gen-card-ul-li{
  text-align: left;
  border: none;
  padding: 5px 5px 0px 5px;
  margin: 0px 0px;
  color: var(--text-cor-padrao);
  display: flex;
  justify-content: space-between;
  border-radius: 13px;
  background: var(--linear-gradient-0deg);
  flex-wrap: wrap;
  align-items: flex-start;
}

.gen-card-ul-li-span{
  border-radius: 5px;
  padding: 0px 5px;
  display: flex;
  color: var(--text-cor-padrao);
  align-items: center;
  font-size: 13px;
  justify-content: flex-start;
  font-weight: 500;
}

.gen-card-ul-li-div{
  background: var(--background);
  border-radius: 5px;
  display: flex;
  color: var(--text-cor-padrao);
  font-size: 13px;
  padding: 5px 0px 5px 10px;
  justify-content: flex-start;
  font-weight: 500;
  min-width: 100%;
  min-height: 30px;
  max-height: 30px;
  overflow: auto;
}

.gen-card-ul-li-primary{
  background: var(--cor-primaria);
  border-radius: 5px;
  padding: 0px 5px;
  display: flex;
  color: #fff;
  align-items: center;
  font-size: 12px;
}

.gen-card-ul-li-danger{
  background: var(--cor-danger);
  border-radius: 5px;
  padding: 0px 5px;
  display: flex;
  color: #fff;
  align-items: center;
  font-size: 12px;
}

.card-padrao{
  border-radius: 5px;
  padding: 4px;
  background: var(--linear-gradient-0deg);
  border: var(--border-padrao);
  box-shadow: var(--box-shadow_p);
}

.card-padrao-img{
  width: 100%;
  height: 145px;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  border-radius: 10px 10px 0px 0px;
  position: relative;
  background-color: #00000094;
}

.card-padrao-img-sombra{
  width: 100%;
  height: 145px;
  position: absolute;
  background: linear-gradient(130deg, #21212194 5%, transparent 100%);
  border-radius: 10px 10px 0px 0px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.card-padrao-img-title{
  font-size: 26px;
  color: #fff;
  text-transform: uppercase;
  font-weight: bold;
  padding-left: 25px;
  text-align: left;
  line-height: 30px;
}

.card-padrao-footer{
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
}

.card-padrao-footer-div-menu{
  position: absolute;
  right: 0;
  top: 0;
  padding: 10px;
  display: flex;
  width: 40px;
  height: 70px;
  justify-content: space-between;
  flex-wrap: wrap;
  flex-direction: column;
}

.card-padrao-footer-div-menu i{
  cursor: pointer;
  color: var(--text-cor-sub) !important;
  opacity: 0.5;
  font-size: 20px;
}

.card-padrao-footer-div-menu i:hover{
  opacity: 0.9;
}

.card-padrao-list{
  border-radius: 5px;
  padding: 4px;
  background: var(--linear-gradient-0deg);
  border: var(--border-padrao);
  box-shadow: var(--box-shadow_p);
  cursor: default !important;
}

.card-padrao-list:hover{
  /* transform: scale(1.033);
  border-color: var(--cor-primaria); */
  cursor: pointer;
}

.card-padrao-list-box{
  display: flex;
  align-items: stretch;
  height: 100%;
  width: 100%;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  font-size: 13px;
}

.card-padrao-list-box-bg-value{
  background: var(--input-background);
  border-radius: 5px;
  /* display: flex; */
  color: var(--text-cor-padrao);
  font-size: 13px;
  padding: 0px 5px;
  /* justify-content: flex-start; */
  font-weight: 500;
}

.card-padrao-list-box-nobg-value{
 /* background: var(--background); */
 border-radius: 5px;
 /* display: flex; */
 color: var(--text-cor-padrao);
 font-size: 13px;
 padding: 0px 5px;
 /* justify-content: flex-start; */
 font-weight: 600;
 text-align: left;
}

.card-padrao-list-box-div-buttom{
  display: flex;
  align-items: stretch;
  height: 100%;
  width: 100%;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
}

.card-body-row-oculta{
  font-size: 13px;
  border-top: 1px solid var(--border-cor);
  padding-top: 10px !important;
}

.card-padrao-list-box-div-table{
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  border-top: 1px solid var(--border-cor);
  border-radius: 0px;
  flex-direction: column;
}

.card-padrao-list-span-pesquisa{
  margin: 0px 5px;
  padding: 5px 15px;
  border-bottom: 2.5px solid var(--cor-danger) !important;
  text-align: left;
  display: inline-block;
  background: var(--background);
  border-radius: 10px 10px 0px 0px;
  border: 1px solid var(--border-cor);
}

.card-padrao-list-img{
  min-width: 60px;
  width: 60px;
  height: 60px;
  border-radius: 35%;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  position: relative;
  background-color: #00000094;
}

.card-padrao-list-img-sombra{
  min-width: 60px;
  width: 60px;
  height: 60px;
  border-radius: 35%;
  position: absolute;
  background: linear-gradient(130deg, #21212194 5%, transparent 100%);
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.card-padrao-list-img-title{
  color: var(--text-cor-padrao);
  font-size: 1rem;
  padding: 0px 0px 0px 15px;
  line-height: 1.3;
  text-transform: uppercase;
  font-weight: bold;
  text-align: left;
}

.card-padrao-list-primaria{
  display: flex;
  color: var(--cor-primaria);
  align-items: center;
  font-size: 14px;
}

.card-padrao-list-sucesso{
  display: flex;
  color: var(--cor-sucesso);
  align-items: center;
  /* font-size: 14px; */
}

.card-padrao-list-danger{
  display: flex;
  color: var(--cor-danger);
  align-items: center;
  /* font-size: 14px; */
}

.card-padrao-list-dataPipe-alerta{
  position: relative;
  top: 1px;
  left: 4px;
  color: var(--cor-alerta);
}

.card-padrao-list-dataPipe-danger{
  position: relative;
  top: 1px;
  left: 4px;
  color: var(--cor-danger);
}

/* PAGINAÇÃO */
.pagination-box-result{
  color: var(--text-cor-sub);display: flex;align-items: center;font-size: 15px;
}

.select-box-result{
  background: var(--background) !important;
  color: var(--text-cor-padrao) !important;
  height: 25px;
  padding: 0 10px !important;
  width: auto !important;
  border-color: var(--border-cor) !important;
  text-align: center;
}

.pagination-info-box-result{
  text-align: right;
  padding-top: 3px !important;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 0;
  padding: 0;
}

.info-pagination-box-result{
  position: relative;
  display: block;
  top: 10px;
  /* justify-content: flex-end; */
  padding: 0;
  margin: 0;
  text-align: center;
}

.ngx-pagination .disabled {
  color: #747474!important;
}

.ngx-pagination{
padding: 0px !important;
width: 100%;
}

.ngx-pagination .current {
  background: var(--radial-gradient-1) !important;
  color: #fff !important;
  border-radius: 20px;
  /* min-width: 28.5px; */
}

.ngx-pagination a:hover,
.ngx-pagination button:hover {
  background: var(--radial-gradient-1) !important;
  color: #fff !important;
  border-radius: 20px;
  min-width: 28.5px;
}

.ngx-pagination a,
.ngx-pagination button {
  color: var(--text-cor-padrao) !important;
  text-decoration: none !important;
}
/* FIM PAGINAÇÃO */


/* ANIMATE LOADING */

.pulsate{
  background: var(--pulsate-background);
  background-size: 400% 400%;
  -webkit-animation: Gradient 2.25s ease infinite;
  -moz-animation: Gradient 2.25s ease infinite;
  animation: Gradient 2.25s ease infinite;

  min-height: 55vh;
  border: var(--border-padrao);
  border-radius: 5px;
  padding: 20px !important;
  box-shadow: var(--box-shadow_p);
  width: 100%;
  display: flex;
  overflow: auto;flex-direction: column;
}

.pulsate-input{
  background: var(--pulsate-background-input);
  background-size: 400% 400%;
  -webkit-animation: Gradient 2.25s ease infinite;
  -moz-animation: Gradient 2.25s ease infinite;
  animation: Gradient 2.25s ease infinite;

  min-height: 45px;
  border: var(--border-padrao);
  border-radius: 5px;
  padding: 0px !important;
  /* box-shadow: var(--box-shadow_p); */
  width: 100%;
  display: flex;
  overflow: auto;flex-direction: column;
}

@-webkit-keyframes Gradient {
  0% {
    background-position: 0% 50%
  }
  50% {
    background-position: 100% 50%
  }
  100% {
    background-position: 0% 50%
  }
}

@-moz-keyframes Gradient {
  0% {
    background-position: 0% 50%
  }
  50% {
    background-position: 100% 50%
  }
  100% {
    background-position: 0% 50%
  }
}

@keyframes Gradient {
  0% {
    background-position: 0% 50%
  }
  50% {
    background-position: 100% 50%
  }
  100% {
    background-position: 0% 50%
  }
}

/* FIM ANIMATE LOADING */

/* TABLE THEME*/

.table-dark, .table-light {
  background: var(--background-p-b)
}

.table>:not(caption)>*>*{
  background-color: var(--background-p-b) !important;
}

.table-sm>:not(caption)>*>*{
  border-bottom-color: var(--border-cor) !important;
}

.table-dark td,.table-dark th,.table-dark thead th {
  border-color: var(--border-cor) !important;
  background: var(--background-p-b) !important;
  color: var(--text-cor-padrao);
}

.table-hover>tbody>tr:hover{
  --bs-table-accent-bg: var(--background) !important;
}


/* TABLE THEME*/


/* Validadores */

p.error-message, span.error-message {
  color: var(--cor-danger);
  padding: 0px;
  margin: 0px;
  padding-left: 10px;
}

.border-danger {
  border-color: var(--cor-danger) !important;
  background: #d6050b08 !important;
  border-radius: 0px !important;
}

.border-alerta {
  border-color: var(--cor-alerta) !important;
  background: #ddc31208 !important;
  border-radius: 0px !important;
  color: var(--cor-black);
}

/* CSS personalizado para a coluna fixa */
.sticky-col {
  position: sticky;
  right: 0;
  background-color: #fff;
  z-index: 1;
}

.sticky-col-buttom{
  border: none !important;
  font-size: 18px !important;
  padding: 8px 8px !important;
  margin: 0 !important;
  min-width: 30px !important;
  border-radius: 10px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Tabela */

.ag-table-tr{
  cursor: pointer;
  height: 50px;
  vertical-align: middle;
}

.ag-table-td{
  cursor: pointer;
  height: 50px;
  vertical-align: middle;
  text-align: left;
}

/*  */

.ag-icon-click{
  cursor:pointer;
  padding: 0px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex-wrap: wrap;
  font-size: 30px;
  color: var(--cor-primaria);
}

.ag-tag-label{
  background: var(--background);
  color: var(--text-cor-padrao);
  padding: 2px 10px;
  border-radius: 5px;
  margin: 0px 4px;
  font-size: 12px;
  border: var(--border-padrao);
}

/* Ícone para o estado  "false" */
.accordion-button.collapsed::after {
  content: '\F229';
  font-family: 'bootstrap-icons';
  color: var(--cor-secundaria);
  background-image: none;
  font-size: 15px;
  position: relative;
  top: 2px;
  left: 5px;
  transform: rotate(0);
}

/* Ícone para o estado "true" (colapsado) */
.accordion-button:not(.collapsed)::after {
  content: '\F229';
  font-family: 'bootstrap-icons';
  color: var(--cor-primaria);
  background-image: none;
  font-size: 15px;
  position: relative;
  top: -2px;
  left: 0px;
}


.modal {
  animation: none !important;
}

.mat-sort-header-content{
  text-align: left !important;
}


.highlight {
  background-color: yellow !important;
  color: black !important;
}








.custom-button-group {
  display: inline-flex;
  min-width: 100%;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
}

.custom-button-div {
  margin-top: 5px;
  margin-bottom: 5px;
}

.custom-button {
  background: transparent;
  color: var(--text-cor-padrao) !important;
  border: var(--border-padrao);
  text-transform: uppercase !important;
  padding: 8px 8px !important;
  border-radius: 10px !important;
  font-size: 75% !important;
  font-weight: 500;
  width: 100% !important;
  /* min-width: 90px !important; */
  /* max-width: 165px !important; */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  line-height: 20px;
  vertical-align: middle;
}

/* .custom-button-div:first-child .custom-button {
  border-radius: 0px 0 0 0px !important;
}

.custom-button-div:last-child .custom-button {
  border-radius: 0 0px 0px 0 !important;
} */

.custom-button:hover {
  color: #fff !important;
  background: var(--cor-primaria) !important;
  border-color: var(--cor-primaria) !important;
}

.custom-button:hover i {
  color: #fff !important;
}

/* @media (min-width: 1400px){
  .custom-button {
    min-width: 150px !important;
  }
} */


.inverter::before {
  transform: scaleX(-1);
}
