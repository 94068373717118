/* Calendario */

.calendar {
  color: var(--text-cor-padrao);
}

.fc-scrollgrid-sync-inner a, .fc-list-day-cushion.fc-cell-shaded a {
  color: var(--text-cor-padrao) !important;
  text-decoration: none;
  font-weight: bold;
}

.fc-daygrid-event-dot {
  border-color: var(--cor-primaria);
}

.fc-v-event, .fc-h-event {
  background-color: var(--cor-primaria);
  border: 1px solid var(--border-cor);
}

.fc .fc-timegrid-col.fc-day-today, .fc .fc-daygrid-day.fc-day-today, .fc-theme-standard .fc-list-day-cushion{
  background: var(--background);
  background-color: var(--background);
}

.fc-day-today .fc-scrollgrid-sync-inner a.fc-daygrid-day-number{
  color: var(--lw-cor-s) !important;
}

.fc-daygrid-day, .fc .fc-daygrid-day-frame {
  max-height: 120px !important; 
  height: 120px !important; 
  min-height: 120px !important; 
}

.fc-theme-standard td, .fc-theme-standard th, .fc-theme-standard .fc-scrollgrid{
  border: 1px solid var(--border-cor);
  background: var(--linear-gradient-0deg);
}

.fc-theme-standard .fc-popover{
  border: 1px solid var(--border-cor);
  background: var(--linear-gradient-0deg);
}

.fc-theme-standard .fc-list{
  border: 1px solid var(--border-cor);
}

.fc-header-toolbar.fc-toolbar.fc-toolbar-ltr {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px !important;
}

.fc-toolbar-chunk {
  font-size: 13px;
}

.fc-toolbar-title {
  margin-bottom: 5px !important;
  margin-top: 5px !important;
  text-transform: uppercase;
}

.event-title {
  font-weight: bold;
  font-size: 13px;
}

.event-description {
  font-size: 13px;
}

.fc .fc-button-primary{
  background-color: var(--lw-cor-6);
  border-color: var(--lw-cor-6);
  color: var(--lw-cor-5);
  /* margin-bottom: 5px !important; */
}

.fc .fc-button-primary:not(:disabled).fc-button-active, .fc .fc-button-primary:not(:disabled):active {
  background-color: var(--lw-cor-2);
  border-color: var(--lw-cor-2);
  color: var(--lw-cor-5);
}

.fc .fc-button-primary:hover {
  background-color: var(--lw-cor-2);
  border-color: var(--lw-cor-2);
  color: var(--lw-cor-5);
}

.fc .fc-multimonth-singlecol .fc-multimonth-header {
  background: var(--linear-gradient-0deg);
}

.fc .fc-cell-shaded, .fc .fc-day-disabled {
  background: var(--linear-gradient-0deg);
}

.fc .fc-multimonth {
  border: 1px solid var(--background);
}

.fc .fc-multimonth-title {
  color: var(--text-cor-padrao);
  text-transform: uppercase;
}

.fc-multimonth-daygrid-table {
  height: auto !important;
}

.fc .fc-timegrid-now-indicator-line {
  border-color: var(--lw-cor-p);
}

.fc .fc-timegrid-now-indicator-arrow {
  border-color: var(--lw-cor-p);
  border-bottom-color: transparent;
  border-top-color: transparent;
}

