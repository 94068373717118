.mdc-label{
  color: var(--text-cor-padrao) !important;
  display: flex;
  align-items: center;
}

.mdc-checkbox__background {
  border-color: var(--text-cor-padrao) !important;
}

.mat-radial-primary .mdc-checkbox .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background,
.mat-radial-primary .mdc-checkbox .mdc-checkbox__native-control:enabled:indeterminate~.mdc-checkbox__background,
.mat-radial-primary .mdc-checkbox .mdc-checkbox__native-control[data-indeterminate=true]:enabled~.mdc-checkbox__background {
  border-color: var(--cor-primaria) !important;
  background-color: var(--cor-primaria) !important;
}

.mat-radial-primary .mdc-checkbox:active .mdc-checkbox__native-control:checked~.mdc-checkbox__ripple {
  background-color: var(--cor-primaria) !important;
}

.mat-radial-primary .mdc-checkbox:hover .mdc-checkbox__native-control:checked~.mdc-checkbox__ripple {
  background-color: var(--cor-primaria) !important;
}

.mat-radial-primary .mdc-checkbox .mdc-checkbox__native-control:focus:checked~.mdc-checkbox__ripple {
  background-color: var(--cor-primaria) !important;
}


.mat-radial-danger .mdc-checkbox .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background,
.mat-radial-danger .mdc-checkbox .mdc-checkbox__native-control:enabled:indeterminate~.mdc-checkbox__background,
.mat-radial-danger .mdc-checkbox .mdc-checkbox__native-control[data-indeterminate=true]:enabled~.mdc-checkbox__background {
  border-color: var(--cor-danger) !important;
  background-color: var(--cor-danger) !important;
}

.mat-radial-danger .mdc-checkbox:active .mdc-checkbox__native-control:checked~.mdc-checkbox__ripple {
  background-color: var(--cor-danger) !important;
}

.mat-radial-danger .mdc-checkbox:hover .mdc-checkbox__native-control:checked~.mdc-checkbox__ripple {
  background-color: var(--cor-danger) !important;
}

.mat-radial-danger .mdc-checkbox .mdc-checkbox__native-control:focus:checked~.mdc-checkbox__ripple {
  background-color: var(--cor-danger) !important;
}

/* .mat-mdc-slide-toggle .mdc-switch.mdc-switch--selected:enabled .mdc-switch__icon { */
  /* background: transparent !important;
  fill: var(--background) !important; */
/* } */

.mat-mdc-slide-toggle .mdc-switch:enabled .mdc-switch__track::after{
  background: #e0e0e0 !important;
}

.mat-mdc-slide-toggle .mdc-switch.mdc-switch--selected:enabled .mdc-switch__handle::after{
  background: var(--cor-primaria)  !important;
}

.mat-mdc-slide-toggle .mdc-switch.mdc-switch--unselected:enabled .mdc-switch__handle::after{
  background: var(--cor-danger)  !important;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: red;
}

.mat-slide-toggle .mat-slide-toggle-bar {
  background-color: var(--pale-green);
}

.mdc-tooltip__surface.mdc-tooltip__surface-animation {
  padding: 10px 15px;
  font-size: 15px !important;
  min-width: 300px;
  background: var(--text-cor-padrao) !important;
  color: var(--background) !important;
  line-height: 1.4 !important;
}

.mat-elevation-z8, .mat-mdc-elevation-specific.mat-elevation-z8 {
  box-shadow: var(--box-shadow_p) !important;
  border: var(--border-padrao);
  background: var(--linear-gradient-0deg);
  border-radius: 6px;
}

.mat-mdc-menu-item, .mat-mdc-menu-item:visited, .mat-mdc-menu-item:link {
  color: var(--text-cor-padrao) !important;
}

.mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled) {
  background: var(--linear-gradient-0deg);
  box-shadow: var(--box-shadow_s);
}

.mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled) .mdc-evolution-chip__text-label {
  color: var(--text-cor-sub) !important;
  cursor: pointer;
}

.cdk-overlay-container{
  z-index: 99999 !important;
}

.mat-mdc-paginator {
  background: var(--linear-gradient-0deg) !important;
  color: var(--text-cor-padrao);
}

.mat-mdc-select-value {
  color: var(--text-cor-padrao);
  background: var(--linear-gradient-0deg);
}

.mat-mdc-paginator-outer-container{
  color: var(--text-cor-padrao);
}

.mat-mdc-select-arrow svg{
  color: var(--text-cor-padrao);
}

.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading, .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch, .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing{
  border-color: var(--border-cor) !important;
}

.mat-mdc-icon-button svg, .mat-mdc-icon-button img {
  fill: var(--text-cor-padrao);
}

.mat-mdc-icon-button[disabled] .mat-mdc-paginator-icon {
  fill: var(--text-cor-padrao) !important;
  opacity: 0.5;
}

.mat-mdc-icon-button:hover .mat-mdc-button-persistent-ripple::before{
  opacity: 0.8;
  z-index: -1;
}

.mat-mdc-icon-button .mat-mdc-button-persistent-ripple::before{
  background-color: var(--background) !important;
}

.mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text{
  color: var(--cor-primaria) !important;
}

.mat-mdc-tab.mdc-tab--active .mdc-tab__text-label{
  color: var(--text-cor-padrao) !important;
}

.mat-mdc-tab .mdc-tab-indicator__content--underline {
  border-color: var(--cor-primaria) !important;
}

.mat-mdc-tab .mdc-tab__text-label {
  color: var(--text-cor-padrao) !important;
  font-size: 13px;
  letter-spacing: 0;
}

.mat-mdc-tab {
  font-size: 13px !important;
  padding: 0px 10px !important;
}

.mat-mdc-tab .mdc-tab__ripple::before{
  background-color: var(--cor-primaria) !important;
}

.mat-mdc-tab.mdc-tab--active .mdc-tab__ripple::before, .mat-mdc-tab.mdc-tab--active .mat-ripple-element{
  background-color: var(--cor-primaria) !important;
}

.mat-mdc-tab-header-pagination-chevron {
  border-color: var(--cor-primaria) !important;
}

.mat-mdc-tab-labels{
  height: 32px;
}

.mat-mdc-tab-group.mat-mdc-tab-group-stretch-tabs>.mat-mdc-tab-header .mat-mdc-tab{
  padding: 0px 10px !important;
  margin: 0px 0px !important;
  height: 100%;
}


/* mat-tab-header.mat-mdc-tab-header {
    background: var(--background);
    border-radius: 5px 5px 0px 0px;
} */

/* .mat-mdc-tab-list{
  overflow: auto;
  transform: translateX(0px) !important;
  width: 100%;
} */

/* .mat-mdc-tab-header-pagination-controls-enabled .mat-mdc-tab-header-pagination{
  display: none !important;
} */

/* MatListModule */

.mdc-list-item__content{
  display: flex !important;
}

.mat-mdc-list-option .mdc-checkbox .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background, .mat-mdc-list-option .mdc-checkbox .mdc-checkbox__native-control:enabled:indeterminate~.mdc-checkbox__background, .mat-mdc-list-option .mdc-checkbox .mdc-checkbox__native-control[data-indeterminate=true]:enabled~.mdc-checkbox__background{
  background-color: var(--cor-primaria) !important;
  border-color: var(--cor-primaria) !important;
}

.mdc-list-item:hover, .mdc-list-item__primary-text{
  color: var(--text-cor-padrao) !important;
}

.mdc-list-item:hover::before {
  background-color: var(--cor-secundaria) !important;
}

.mdc-list-item--with-trailing-checkbox.mdc-list-item{
  padding-left: 0px !important;
}

.mdc-list-item--with-trailing-checkbox .mdc-list-item__end {
  margin-right: 0px !important;
}


/* Estilo radial para o mat-radio-button */
.mat-radial-primary.mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled:checked+.mdc-radio__background .mdc-radio__outer-circle {
  border-color: var(--cor-primaria);
}

.mat-radial-primary.mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled+.mdc-radio__background .mdc-radio__inner-circle {
    border-color: var(--cor-primaria);
}

.mat-radial-primary.mat-mdc-radio-button.mat-mdc-radio-checked .mdc-radio__background::before {
  background-color: var(--cor-primaria);
}

.mat-radial-primary.mat-mdc-radio-button .mdc-radio:hover .mdc-radio__native-control:enabled:checked+.mdc-radio__background .mdc-radio__outer-circle {
  border-color: var(--cor-primaria);
}

.mat-radial-primary.mat-mdc-radio-button .mdc-radio:hover .mdc-radio__native-control:enabled+.mdc-radio__background .mdc-radio__inner-circle {
  border-color: var(--cor-primaria);
}

.mat-radial-primary.mat-mdc-radio-button.mat-mdc-radio-checked .mat-ripple-element {
  background-color: var(--cor-primaria);
}

.mat-radial-primary.mat-mdc-radio-button .mdc-radio:not(:disabled):active .mdc-radio__native-control:enabled:checked+.mdc-radio__background .mdc-radio__outer-circle {
  border-color: var(--cor-primaria);
}

.mat-radial-primary.mat-mdc-radio-button .mdc-radio:not(:disabled):active .mdc-radio__native-control:enabled+.mdc-radio__background .mdc-radio__inner-circle {
  border-color: var(--cor-primaria);
}

.mat-radial-primary.mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled:not(:checked)+.mdc-radio__background .mdc-radio__outer-circle {
  border-color: var(--text-cor-padrao);
}

.mat-radial-primary.mat-mdc-radio-button .mdc-radio:hover .mdc-radio__native-control:enabled:not(:checked)+.mdc-radio__background .mdc-radio__outer-circle {
  border-color: var(--text-cor-padrao);
}

.mat-radial-primary.mat-mdc-radio-button .mdc-radio .mdc-radio__background::before {
  background-color: var(--cor-primaria);
}
