/* UTILIDADES */

.breadcrumb-item a{
  cursor: pointer;
}

body{
  overflow: auto !important;
  background: var(--background) !important;
}

/* BACKGROUND ELEMENT */
.background{
  background: var(--background) !important;
}

/* COLOR BACKGROUND */
.background-cor-primaria{
  background: var(--cor-primaria) !important;
}
.background-cor-secundaria{
  background: var(--cor-secundaria) !important;
}
.background-cor-destaque{
  background: var(--cor-destaque) !important;
}
.background-cor-alerta{
  background: var(--cor-alerta)  !important;
}
.background-cor-danger{
  background: var(--cor-danger) !important;
}
.background-cor-sucesso{
  background: var(--cor-sucesso) !important;
}
.background-cor-info{
  background: var(--cor-info) !important;
}
.background-cor-branco{
  background: var(--cor-branco) !important;
}

.background-cor-preto{
  background: var(--cor-black) !important;
}


/* TEXT */
.text-padrao{
  color: var(--text-cor-padrao);
  text-decoration: none;
}
.text-padrao:hover{
  color: var(--text-cor-padrao);
  opacity: 0.8;
}
.text-background-clip{
  background: var(--text-background-clip) !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
  background-clip: text !important;
}

/* COLOR TEXT */
.text-cor-padrao{
  color: var(--text-cor-padrao);
}
.text-cor-sub{
  color: var(--text-cor-sub) !important
}
.text-cor-primaria{
  color: var(--cor-primaria) !important;
}
.text-cor-secundaria{
  color: var(--cor-secundaria) !important;
}
.text-cor-destaque{
  color: var(--cor-destaque) !important;
}
.text-cor-alerta{
  color: var(--cor-alerta) !important;
}
.text-cor-danger{
  color: var(--cor-danger) !important;
}
.text-cor-sucesso{
  color: var(--cor-sucesso) !important;
}
.text-cor-info{
  color: var(--cor-info) !important;
}
.text-cor-branco{
  color: var(--cor-branco) !important;
}
.text-cor-black{
  color: var(--cor-black) !important;
}

/* HR */
.hr-padrao{
  background: var(--hr-padrao) !important;
  height: 1px;
  opacity: 1;
}

/* SCROLLBAR */
::-webkit-scrollbar {
  width: 9px;
}
::-webkit-scrollbar {
  height: 9px;
}
::-webkit-scrollbar-thumb {
  background-color: var(--lw-cor-6);
  border-radius: 3px;
  border: 1.5px solid var(--background);
}
::-webkit-scrollbar-track {
  background-color: var(--background);
}
::-webkit-scrollbar-thumb:hover {
  background-color: var(--lw-cor-4);
}
::-webkit-scrollbar {
  width: 9px;
  height: 9px;
  border-radius: 3px;
}

/* BORDER COLOR HOVER */
.hover-border-primaria:hover{
  border-color: var(--cor-primaria) !important;
}
.hover-border-secundaria:hover{
  border-color: var(--cor-secundaria) !important;
}
.hover-border-destaque:hover{
  border-color: var(--cor-destaque) !important;
}
.hover-border-alerta:hover{
  border-color: var(--cor-alerta) !important;
}
.hover-border-danger:hover{
  border-color: var(--cor-danger) !important;
}
.hover-border-sucesso:hover{
  border-color: var(--cor-sucesso) !important;
}
.hover-border-info:hover{
  border-color: var(--cor-info) !important;
}
.hover-border-banco:hover{
  border-color: var(--cor-branco) !important;
}

/* FORM */
.form-control {
  border: var(--border-padrao);
  height: 45px;
  border-radius: 15px;
  margin-top: 4px;
}
.form-floating>.form-control:focus~label, .form-floating>.form-control:not(:placeholder-shown)~label, .form-floating>.form-select~label {
  transform: scale(.7) translateY(-.4rem) translateX(.15rem) !important;
}

/* INPUT */
input:-webkit-autofill,
input:focus,
input:hover,
input:active,
textarea:-webkit-autofill,
textarea:focus,
textarea:hover,
textarea:active {
  -webkit-text-fill-color: var(--text-cor-padrao) !important;
  -webkit-box-shadow: var(--webkit-box-shadow) !important;
  border: none !important;
  border-bottom: 1px solid var(--cor-secundaria) !important;
  caret-color: var(--text-cor-sub) !important;
  transition: .3s;
  border-radius: 0px !important;
}

input:disabled,
textarea:disabled {
  -webkit-text-fill-color: var(--text-cor-padrao) !important;
  -webkit-box-shadow: var(--webkit-box-shadow) !important;
  box-shadow: var(--webkit-box-shadow) !important;
  border: none !important;
  border-bottom: 1px solid var(--background) !important;
  caret-color: var(--text-cor-sub) !important;
  /* transition: .3s; */
  /* border-radius: 0px !important; */
}


.input-disabled {
  cursor: not-allowed;
}

input::placeholder, textarea::placeholder {
  color: #999999;
}
.input-text-padrao input[type="file"] {
  display: none;
}
.input-text-padrao{
  background: var(--input-background) !important;
  color: var(--text-cor-padrao) !important;
  border: none;
  border-bottom: var(--border-padrao) !important;
  border-radius: 5px !important;
}

.label-input-text-padrao{
  color: var(--text-cor-padrao) !important;
  text-align: left !important;
}

/* BUTTONS */
.btn-principal-primary{
  min-height: 65px;
  width: 100%;
  border-radius: 15px !important;
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  box-shadow: var(--box-shadow_s);
  padding: 8px 16px !important;
  background: var(--cor-primaria) !important;
  border-color: var(--cor-primaria);
}
.btn-principal-primary:hover{
  background: var(--cor-primaria) !important;
  border-color: var(--cor-primaria) !important;
  opacity: .9 !important;
}
.btn-principal-primary-nome{
  padding-top: 10px;
  color: #fff;
  padding-bottom: 10px;
  font-size: 15px;
}
.btn-principal-primary-icon{
  font-size: 25px;
  color: #fff;
  font-weight: bold;
}

.btn-primary{
  background: var(--cor-primaria) !important;
  border: none !important;
  border-color: var(--cor-primaria) !important;
  text-transform: uppercase !important;
  padding: 8px 8px !important;
  border-radius: 10px !important;
  font-size: 75% !important;
  min-width: 90px !important;
  font-weight: 500;
}
.btn-primary:hover{
  background: var(--cor-primaria) !important;
  border-color: var(--cor-primaria) !important;
  opacity: .9 !important;
}

.btn-secondary{
  background: var(--cor-secundaria) !important;
  border: none !important;
  border-color: var(--cor-secundaria) !important;
  text-transform: uppercase !important;
  padding: 8px 8px !important;
  border-radius: 10px !important;
  font-size: 75% !important;
  min-width: 90px !important;
  font-weight: 500;
}
.btn-secondary:hover{
  background: var(--cor-secundaria) !important;
  border-color: var(--cor-secundaria) !important;
  opacity: .9 !important;
}

.btn-success{
  background: var(--cor-sucesso) !important;
  border: none !important;
  border-color: var(--cor-sucesso) !important;
  text-transform: uppercase !important;
  padding: 8px 8px !important;
  border-radius: 10px !important;
  font-size: 75% !important;
  min-width: 90px !important;
  font-weight: 500;
}
.btn-success:hover{
  background: var(--cor-sucesso) !important;
  border-color: var(--cor-sucesso) !important;
  opacity: .9 !important;
}

.btn-danger{
  background: var(--cor-danger) !important;
  border: none !important;
  border-color: var(--cor-danger) !important;
  text-transform: uppercase !important;
  padding: 8px 8px !important;
  border-radius: 10px !important;
  font-size: 75% !important;
  min-width: 90px !important;
  font-weight: 500;
}
.btn-danger:hover{
  background: var(--cor-danger) !important;
  border-color: var(--cor-danger) !important;
  opacity: .9 !important;
}

.btn-warning{
  background: var(--cor-alerta) !important;
  border: none !important;
  border-color: var(--cor-alerta) !important;
  text-transform: uppercase !important;
  padding: 8px 8px !important;
  border-radius: 10px !important;
  font-size: 75% !important;
  min-width: 90px !important;
  font-weight: 500;
}
.btn-warning:hover{
  background: var(--cor-alerta) !important;
  border-color: var(--cor-alerta) !important;
  opacity: .9 !important;
}

.btn-outline-primary{
  background: var(--input-background);
  color: var(--text-cor-padrao) !important;
  border: var(--border-padrao);
  text-transform: uppercase !important;
  padding: 8px 8px !important;
  border-radius: 10px !important;
  font-size: 75% !important;
  min-width: 90px !important;
  font-weight: 500;
}
.btn-outline-primary:hover{
  color: #fff !important;
  background: var(--cor-primaria) !important;
  border-color: var(--cor-primaria) !important;
  opacity: .9 !important;
}

.btn-outline-primary-s{
  background: var(--button-background);
  color: var(--text-cor-padrao) !important;
  border: var(--border-padrao);
  text-transform: uppercase !important;
  padding: 8px 8px !important;
  border-radius: 10px !important;
  font-size: 75% !important;
  min-width: 90px !important;
  font-weight: 500;
}
.btn-outline-primary-s:hover{
  color: #fff !important;
  background: var(--cor-primaria) !important;
  border-color: var(--cor-primaria) !important;
  opacity: .9 !important;
}

.btn-outline-secondary{
  background: var(--button-background);
  color: var(--text-cor-padrao) !important;
  border: var(--border-padrao);
  text-transform: uppercase !important;
  padding: 8px 8px !important;
  border-radius: 10px !important;
  font-size: 75% !important;
  min-width: 90px !important;
  font-weight: 500;
}
.btn-outline-secondary:hover{
  color: #fff !important;
  background: var(--cor-secundaria) !important;
  border-color: var(--cor-secundaria) !important;
  opacity: .9 !important;
}

.btn-outline-success{
  background: var(--button-background);
  color: var(--text-cor-padrao) !important;
  border: var(--border-padrao);
  text-transform: uppercase !important;
  padding: 8px 8px !important;
  border-radius: 10px !important;
  font-size: 75% !important;
  min-width: 90px !important;
  font-weight: 500;
}
.btn-outline-success:hover{
  color: #fff !important;
  background: var(--cor-sucesso) !important;
  border-color: var(--cor-sucesso) !important;
  opacity: .9 !important;
}

.btn-outline-danger{
  background: var(--button-background);
  color: var(--text-cor-padrao) !important;
  border: var(--border-padrao);
  text-transform: uppercase !important;
  padding: 8px 8px !important;
  border-radius: 10px !important;
  font-size: 75% !important;
  min-width: 90px !important;
  font-weight: 500;
}
.btn-outline-danger:hover{
  color: #fff !important;
  background: var(--cor-danger) !important;
  border-color: var(--cor-danger) !important;
  opacity: .9 !important;
}

.btn-outline-warning{
  background: var(--button-background);
  color: var(--text-cor-padrao) !important;
  border: var(--border-padrao);
  text-transform: uppercase !important;
  padding: 8px 8px !important;
  border-radius: 10px !important;
  font-size: 75% !important;
  min-width: 90px !important;
  font-weight: 500;
}
.btn-outline-warning:hover{
  color: #fff !important;
  background: var(--cor-alerta) !important;
  border-color: var(--cor-alerta) !important;
  opacity: .9 !important;
}

button:focus:not(:focus-visible) {
  outline: 0;
  box-shadow: none;
}


/* MODAL */
.modal-content {
  border-radius: 5px;
  border: var(--border-padrao);
  background: var(--linear-gradient-0deg);
  color: var(--text-cor-padrao);
  /* min-height: 410px; */
}
.modal-backdrop.show {
  opacity: 1;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  z-index: 1000;
  background: rgb(0 0 0 / 80%);
}

bs-modal-backdrop.modal-backdrop.show {
  opacity: 1;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  z-index: 1060;
  background: rgba(0, 0, 0, 80%);
}

.modal-header {
  border-bottom: var(--border-padrao);
}
.modal-footer{
  border-top: var(--border-padrao);
}
.modal-fullscreen .modal-dialog {
  max-width: 100%;
  margin: 0;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100vh;
  display: flex;
  position: fixed;
  z-index: 100000;
}

/* TOOLTIPS */
.tooltip-input-icon{
  cursor: pointer;
  padding: 0px 0px 0px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex-wrap: wrap;
  color: var(--cor-secundaria);
  position: relative;top: 1px;
}

/* SIZES */
.min-width{
  min-width: 375px !important;
}
.min-height{
  min-height: 230px !important;
}
.w-50 {
  width: 45%!important;
}

/* CARDS */
.card-body{
  padding: 20px 20px;
  cursor: auto !important;
}
.card-body-row{
  display: flex;
  align-items: flex-end;
  color: var(--text-cor-padrao);
}
.card-body-row-div{
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
}
.card-body-pd{
  padding: 5px 5px 15px 5px;
}
.card-body-time{
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  color: var(--text-cor-padrao);
  padding: 6px 3px 4px;
  min-width: 220px;
}
.card-body-ul{
  text-align: left;
  border: none;
  padding: 0px 0px 5px 0px;
  background: var(--linear-gradient-0deg);
  color: var(--text-cor-padrao);
}

.text-truncate{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  /* display: inline-block;
  min-width: 100px; */
}

/* MARGIN TOP (0 ao 20) */

.ag-m-0 {
  margin: 0px !important;
}

.ag-m-1 {
  margin: 1px !important;
}

.ag-m-2 {
  margin: 2px !important;
}

.ag-m-3 {
  margin: 3px !important;
}

.ag-m-4 {
  margin: 4px !important;
}

.ag-m-5 {
  margin: 5px !important;
}

.ag-m-6 {
  margin: 6px !important;
}

.ag-m-7 {
  margin: 7px !important;
}

.ag-m-8 {
  margin: 8px !important;
}

.ag-m-9 {
  margin: 9px !important;
}

.ag-m-10 {
  margin: 10px !important;
}

.ag-m-11 {
  margin: 11px !important;
}

.ag-m-12 {
  margin: 12px !important;
}

.ag-m-13 {
  margin: 13px !important;
}

.ag-m-14 {
  margin: 14px !important;
}

.ag-m-15 {
  margin: 15px !important;
}

.ag-m-16 {
  margin: 16px !important;
}

.ag-m-17 {
  margin: 17px !important;
}

.ag-m-18 {
  margin: 18px !important;
}

.ag-m-19 {
  margin: 19px !important;
}

.ag-m-20 {
  margin: 20px !important;
}

/* MARGIN TOP (0 ao 20) */

.ag-mt-0 {
  margin-top: 0px !important;
}

.ag-mt-1 {
  margin-top: 1px !important;
}

.ag-mt-2 {
  margin-top: 2px !important;
}

.ag-mt-3 {
  margin-top: 3px !important;
}

.ag-mt-4 {
  margin-top: 4px !important;
}

.ag-mt-5 {
  margin-top: 5px !important;
}

.ag-mt-6 {
  margin-top: 6px !important;
}

.ag-mt-7 {
  margin-top: 7px !important;
}

.ag-mt-8 {
  margin-top: 8px !important;
}

.ag-mt-9 {
  margin-top: 9px !important;
}

.ag-mt-10 {
  margin-top: 10px !important;
}

.ag-mt-11 {
  margin-top: 11px !important;
}

.ag-mt-12 {
  margin-top: 12px !important;
}

.ag-mt-13 {
  margin-top: 13px !important;
}

.ag-mt-14 {
  margin-top: 14px !important;
}

.ag-mt-15 {
  margin-top: 15px !important;
}

.ag-mt-16 {
  margin-top: 16px !important;
}

.ag-mt-17 {
  margin-top: 17px !important;
}

.ag-mt-18 {
  margin-top: 18px !important;
}

.ag-mt-19 {
  margin-top: 19px !important;
}

.ag-mt-20 {
  margin-top: 20px !important;
}

/* MARGIN LEFT (0 ao 20) */

.ag-ml-0 {
  margin-left: 0px !important;
}

.ag-ml-1 {
  margin-left: 1px !important;
}

.ag-ml-2 {
  margin-left: 2px !important;
}

.ag-ml-3 {
  margin-left: 3px !important;
}

.ag-ml-4 {
  margin-left: 4px !important;
}

.ag-ml-5 {
  margin-left: 5px !important;
}

.ag-ml-6 {
  margin-left: 6px !important;
}

.ag-ml-7 {
  margin-left: 7px !important;
}

.ag-ml-8 {
  margin-left: 8px !important;
}

.ag-ml-9 {
  margin-left: 9px !important;
}

.ag-ml-10 {
  margin-left: 10px !important;
}

.ag-ml-11 {
  margin-left: 11px !important;
}

.ag-ml-12 {
  margin-left: 12px !important;
}

.ag-ml-13 {
  margin-left: 13px !important;
}

.ag-ml-14 {
  margin-left: 14px !important;
}

.ag-ml-15 {
  margin-left: 15px !important;
}

.ag-ml-16 {
  margin-left: 16px !important;
}

.ag-ml-17 {
  margin-left: 17px !important;
}

.ag-ml-18 {
  margin-left: 18px !important;
}

.ag-ml-19 {
  margin-left: 19px !important;
}

.ag-ml-20 {
  margin-left: 20px !important;
}

/* MARGIN RIGHT (0 ao 20) */

.ag-mr-0 {
  margin-right: 0px !important;
}

.ag-mr-1 {
  margin-right: 1px !important;
}

.ag-mr-2 {
  margin-right: 2px !important;
}

.ag-mr-3 {
  margin-right: 3px !important;
}

.ag-mr-4 {
  margin-right: 4px !important;
}

.ag-mr-5 {
  margin-right: 5px !important;
}

.ag-mr-6 {
  margin-right: 6px !important;
}

.ag-mr-7 {
  margin-right: 7px !important;
}

.ag-mr-8 {
  margin-right: 8px !important;
}

.ag-mr-9 {
  margin-right: 9px !important;
}

.ag-mr-10 {
  margin-right: 10px !important;
}

.ag-mr-11 {
  margin-right: 11px !important;
}

.ag-mr-12 {
  margin-right: 12px !important;
}

.ag-mr-13 {
  margin-right: 13px !important;
}

.ag-mr-14 {
  margin-right: 14px !important;
}

.ag-mr-15 {
  margin-right: 15px !important;
}

.ag-mr-16 {
  margin-right: 16px !important;
}

.ag-mr-17 {
  margin-right: 17px !important;
}

.ag-mr-18 {
  margin-right: 18px !important;
}

.ag-mr-19 {
  margin-right: 19px !important;
}

.ag-mr-20 {
  margin-right: 20px !important;
}

/* MARGIN BOTTOM (0 ao 20) */

.ag-mb-0 {
  margin-bottom: 0px !important;
}

.ag-mb-1 {
  margin-bottom: 1px !important;
}

.ag-mb-2 {
  margin-bottom: 2px !important;
}

.ag-mb-3 {
  margin-bottom: 3px !important;
}

.ag-mb-4 {
  margin-bottom: 4px !important;
}

.ag-mb-5 {
  margin-bottom: 5px !important;
}

.ag-mb-6 {
  margin-bottom: 6px !important;
}

.ag-mb-7 {
  margin-bottom: 7px !important;
}

.ag-mb-8 {
  margin-bottom: 8px !important;
}

.ag-mb-9 {
  margin-bottom: 9px !important;
}

.ag-mb-10 {
  margin-bottom: 10px !important;
}

.ag-mb-11 {
  margin-bottom: 11px !important;
}

.ag-mb-12 {
  margin-bottom: 12px !important;
}

.ag-mb-13 {
  margin-bottom: 13px !important;
}

.ag-mb-14 {
  margin-bottom: 14px !important;
}

.ag-mb-15 {
  margin-bottom: 15px !important;
}

.ag-mb-16 {
  margin-bottom: 16px !important;
}

.ag-mb-17 {
  margin-bottom: 17px !important;
}

.ag-mb-18 {
  margin-bottom: 18px !important;
}

.ag-mb-19 {
  margin-bottom: 19px !important;
}

.ag-mb-20 {
  margin-bottom: 20px !important;
}

/* PADDING (0 ao 20) */

.ag-p-0 {
  padding: 0px !important;
}

.ag-p-1 {
  padding: 1px !important;
}

.ag-p-2 {
  padding: 2px !important;
}

.ag-p-3 {
  padding: 3px !important;
}

.ag-p-4 {
  padding: 4px !important;
}

.ag-p-5 {
  padding: 5px !important;
}

.ag-p-6 {
  padding: 6px !important;
}

.ag-p-7 {
  padding: 7px !important;
}

.ag-p-8 {
  padding: 8px !important;
}

.ag-p-9 {
  padding: 9px !important;
}

.ag-p-10 {
  padding: 10px !important;
}

.ag-p-11 {
  padding: 11px !important;
}

.ag-p-12 {
  padding: 12px !important;
}

.ag-p-13 {
  padding: 13px !important;
}

.ag-p-14 {
  padding: 14px !important;
}

.ag-p-15 {
  padding: 15px !important;
}

.ag-p-16 {
  padding: 16px !important;
}

.ag-p-17 {
  padding: 17px !important;
}

.ag-p-18 {
  padding: 18px !important;
}

.ag-p-19 {
  padding: 19px !important;
}

.ag-p-20 {
  padding: 20px !important;
}

/* PADDING TOP (0 ao 20) */

.ag-pt-0 {
  padding-top: 0px !important;
}

.ag-pt-1 {
  padding-top: 1px !important;
}

.ag-pt-2 {
  padding-top: 2px !important;
}

.ag-pt-3 {
  padding-top: 3px !important;
}

.ag-pt-4 {
  padding-top: 4px !important;
}

.ag-pt-5 {
  padding-top: 5px !important;
}

.ag-pt-6 {
  padding-top: 6px !important;
}

.ag-pt-7 {
  padding-top: 7px !important;
}

.ag-pt-8 {
  padding-top: 8px !important;
}

.ag-pt-9 {
  padding-top: 9px !important;
}

.ag-pt-10 {
  padding-top: 10px !important;
}

.ag-pt-11 {
  padding-top: 11px !important;
}

.ag-pt-12 {
  padding-top: 12px !important;
}

.ag-pt-13 {
  padding-top: 13px !important;
}

.ag-pt-14 {
  padding-top: 14px !important;
}

.ag-pt-15 {
  padding-top: 15px !important;
}

.ag-pt-16 {
  padding-top: 16px !important;
}

.ag-pt-17 {
  padding-top: 17px !important;
}

.ag-pt-18 {
  padding-top: 18px !important;
}

.ag-pt-19 {
  padding-top: 19px !important;
}

.ag-pt-20 {
  padding-top: 20px !important;
}

/* PADDING LEFT (0 ao 20) */

.ag-pl-0 {
  padding-left: 0px !important;
}

.ag-pl-1 {
  padding-left: 1px !important;
}

.ag-pl-2 {
  padding-left: 2px !important;
}

.ag-pl-3 {
  padding-left: 3px !important;
}

.ag-pl-4 {
  padding-left: 4px !important;
}

.ag-pl-5 {
  padding-left: 5px !important;
}

.ag-pl-6 {
  padding-left: 6px !important;
}

.ag-pl-7 {
  padding-left: 7px !important;
}

.ag-pl-8 {
  padding-left: 8px !important;
}

.ag-pl-9 {
  padding-left: 9px !important;
}

.ag-pl-10 {
  padding-left: 10px !important;
}

.ag-pl-11 {
  padding-left: 11px !important;
}

.ag-pl-12 {
  padding-left: 12px !important;
}

.ag-pl-13 {
  padding-left: 13px !important;
}

.ag-pl-14 {
  padding-left: 14px !important;
}

.ag-pl-15 {
  padding-left: 15px !important;
}

.ag-pl-16 {
  padding-left: 16px !important;
}

.ag-pl-17 {
  padding-left: 17px !important;
}

.ag-pl-18 {
  padding-left: 18px !important;
}

.ag-pl-19 {
  padding-left: 19px !important;
}

.ag-pl-20 {
  padding-left: 20px !important;
}

/* PADDING RIGHT (0 ao 20) */

.ag-pr-0 {
  padding-right: 0px !important;
}

.ag-pr-1 {
  padding-right: 1px !important;
}

.ag-pr-2 {
  padding-right: 2px !important;
}

.ag-pr-3 {
  padding-right: 3px !important;
}

.ag-pr-4 {
  padding-right: 4px !important;
}

.ag-pr-5 {
  padding-right: 5px !important;
}

.ag-pr-6 {
  padding-right: 6px !important;
}

.ag-pr-7 {
  padding-right: 7px !important;
}

.ag-pr-8 {
  padding-right: 8px !important;
}

.ag-pr-9 {
  padding-right: 9px !important;
}

.ag-pr-10 {
  padding-right: 10px !important;
}

.ag-pr-11 {
  padding-right: 11px !important;
}

.ag-pr-12 {
  padding-right: 12px !important;
}

.ag-pr-13 {
  padding-right: 13px !important;
}

.ag-pr-14 {
  padding-right: 14px !important;
}

.ag-pr-15 {
  padding-right: 15px !important;
}

.ag-pr-16 {
  padding-right: 16px !important;
}

.ag-pr-17 {
  padding-right: 17px !important;
}

.ag-pr-18 {
  padding-right: 18px !important;
}

.ag-pr-19 {
  padding-right: 19px !important;
}

.ag-pr-20 {
  padding-right: 20px !important;
}

/* PADDING BOTTOM (0 ao 20) */

.ag-pb-0 {
  padding-bottom: 0px !important;
}

.ag-pb-1 {
  padding-bottom: 1px !important;
}

.ag-pb-2 {
  padding-bottom: 2px !important;
}

.ag-pb-3 {
  padding-bottom: 3px !important;
}

.ag-pb-4 {
  padding-bottom: 4px !important;
}

.ag-pb-5 {
  padding-bottom: 5px !important;
}

.ag-pb-6 {
  padding-bottom: 6px !important;
}

.ag-pb-7 {
  padding-bottom: 7px !important;
}

.ag-pb-8 {
  padding-bottom: 8px !important;
}

.ag-pb-9 {
  padding-bottom: 9px !important;
}

.ag-pb-10 {
  padding-bottom: 10px !important;
}

.ag-pb-11 {
  padding-bottom: 11px !important;
}

.ag-pb-12 {
  padding-bottom: 12px !important;
}

.ag-pb-13 {
  padding-bottom: 13px !important;
}

.ag-pb-14 {
  padding-bottom: 14px !important;
}

.ag-pb-15 {
  padding-bottom: 15px !important;
}

.ag-pb-16 {
  padding-bottom: 16px !important;
}

.ag-pb-17 {
  padding-bottom: 17px !important;
}

.ag-pb-18 {
  padding-bottom: 18px !important;
}

.ag-pb-19 {
  padding-bottom: 19px !important;
}

.ag-pb-20 {
  padding-bottom: 20px !important;
}
