/* DATEPICKER */
/* Personalização do Date Range Datepicker */
.theme-custom-ranger-datepicker{
  position: absolute;
  left: 0%;
  right: 0%;
  transform: translate(-25%, -27%);
  z-index: 1050;
}
/* Box */
.theme-custom-ranger-datepicker.bs-datepicker .bs-media-container {
  background: var(--linear-gradient-0deg) !important;
}

.theme-custom-ranger-datepicker.bs-datepicker{
  box-shadow: var(--box-shadow_p)
}

.theme-custom-ranger-datepicker .bs-datepicker-container {
  padding: 0px;
}
/* Head calendário */
.theme-custom-ranger-datepicker .bs-datepicker-head {
  background-color: var(--cor-secundaria);
  border-radius: 0px 0px 0px 0px;
}
/* Body calendário */
.theme-custom-ranger-datepicker .bs-datepicker-body{
  border: var(--border-padrao);
}
/* Números da semana do ano */
.theme-custom-ranger-datepicker .bs-datepicker-body table td.week span {
  color: var(--cor-secundaria);
}
/* Semana do mês (dom à sab)  */
.theme-custom-ranger-datepicker .bs-datepicker-body table th{
  color: var(--text-cor-padrao);
}
/* Dias mês atual */
.theme-custom-ranger-datepicker .bs-datepicker-body table td {
  color: var(--text-cor-padrao);
}
/* Dia atual */
.theme-custom-ranger-datepicker .custom-datepicker-today{
  background: var(--cor-primaria);
  color: var(--cor-branco) !important;
}
/* Dias sem ser o mês atual */
.theme-custom-ranger-datepicker .bs-datepicker-body table td span.is-other-month, .bs-datepicker-body table td.is-other-month span {
  color: var(--cor-secundaria);
}
/* Dia selecionado */
.theme-custom-ranger-datepicker .bs-datepicker-body table td span.selected,
.theme-custom-ranger-datepicker .bs-datepicker-body table td.selected span,
.theme-custom-ranger-datepicker .bs-datepicker-body table td span[class*=select-]:after,
.theme-custom-ranger-datepicker .bs-datepicker-body table td[class*=select-] span:after {
  background-color: var(--cor-secundaria);
  color: var(--cor-branco);
}
/* Dia antes de selecionar "hover" */
.theme-custom-ranger-datepicker .bs-datepicker-body table td.is-highlighted:not(.disabled):not(.selected) span,
.theme-custom-ranger-datepicker .bs-datepicker-body table td span.is-highlighted:not(.disabled):not(.selected) {
  background-color: var(--cor-secundaria);
  color: var(--cor-branco);
  transition: 0s;
}
.theme-custom-ranger-datepicker .bs-datepicker-body table td.is-active-other-month:not(.disabled):not(.selected) span,
.theme-custom-ranger-datepicker .bs-datepicker-body table td span.is-active-other-month:not(.disabled):not(.selected) {
  background-color: var(--cor-secundaria);
  color: var(--cor-branco);
  transition: 0s;
  cursor: pointer;
}
/* Dia nova seleção */
.theme-custom-ranger-datepicker .bs-datepicker-body table.days td.active:not(.select-start):not(.disabled):hover,
.theme-custom-ranger-datepicker .bs-datepicker-body table.days td.in-range:not(.select-start):not(.disabled):hover,
.theme-custom-ranger-datepicker .bs-datepicker-body table.days span.active:not(.select-start):not(.disabled):hover,
.theme-custom-ranger-datepicker .bs-datepicker-body table.days span.in-range:not(.select-start):not(.disabled):hover {
  background: var(--cor-secundaria);
}
/* Background range de seleção de datas */
.theme-custom-ranger-datepicker .bs-datepicker-body table.days td.active:not(.select-start):not(.disabled):before,
.theme-custom-ranger-datepicker .bs-datepicker-body table.days td.in-range:not(.select-start):not(.disabled):before,
.theme-custom-ranger-datepicker .bs-datepicker-body table.days span.active:not(.select-start):not(.disabled):before,
.theme-custom-ranger-datepicker .bs-datepicker-body table.days span.in-range:not(.select-start):not(.disabled):before {
  background: var(--background);
}

/* Datapick */
/* Box */
.theme-custom-datepicker.bs-datepicker .bs-media-container {
  background: var(--linear-gradient-0deg) !important;
}

.theme-custom-datepicker.bs-datepicker{
  box-shadow: var(--box-shadow_p)
}

.theme-custom-datepicker .bs-datepicker-container {
  padding: 0px;
}
/* Head calendário */
.theme-custom-datepicker .bs-datepicker-head {
  background-color: var(--cor-secundaria);
  border-radius: 0px 0px 0px 0px;
}
/* Body calendário */
.theme-custom-datepicker .bs-datepicker-body{
  border: 1px solid var(--cor-secundaria);
}
/* Números da semana do ano */
.theme-custom-datepicker .bs-datepicker-body table td.week span {
  color: var(--cor-secundaria);
}
/* Semana do mês (dom à sab)  */
.theme-custom-datepicker .bs-datepicker-body table th{
  color: var(--text-cor-padrao);
}
/* Dias mês atual */
.theme-custom-datepicker .bs-datepicker-body table td {
  color: var(--text-cor-padrao);
}
/* Dia atual */
.theme-custom-datepicker .custom-datepicker-today{
  background: var(--background);
  color: var(--text-cor-padrao);
}
/* Dias sem ser o mês atual */
.theme-custom-datepicker .bs-datepicker-body table td span.is-other-month, .bs-datepicker-body table td.is-other-month span {
  color: var(--cor-secundaria);
}
/* Dia selecionado */
.theme-custom-datepicker .bs-datepicker-body table td span.selected,
.theme-custom-datepicker .bs-datepicker-body table td.selected span,
.theme-custom-datepicker .bs-datepicker-body table td span[class*=select-]:after,
.theme-custom-datepicker .bs-datepicker-body table td[class*=select-] span:after {
  background-color: var(--cor-secundaria);
  color: var(--cor-branco);
}
/* Dia antes de selecionar "hover" */
.theme-custom-datepicker .bs-datepicker-body table td.is-highlighted:not(.disabled):not(.selected) span,
.theme-custom-datepicker .bs-datepicker-body table td span.is-highlighted:not(.disabled):not(.selected) {
  background-color: var(--cor-secundaria);
  color: var(--cor-branco);
  transition: 0s;
}
.theme-custom-datepicker .bs-datepicker-body table td.is-active-other-month:not(.disabled):not(.selected) span,
.theme-custom-datepicker .bs-datepicker-body table td span.is-active-other-month:not(.disabled):not(.selected) {
  background-color: var(--cor-secundaria);
  color: var(--cor-branco);
  transition: 0s;
  cursor: pointer;
}
/* Dia nova seleção */
.theme-custom-datepicker .bs-datepicker-body table.days td.active:not(.select-start):not(.disabled):hover,
.theme-custom-datepicker .bs-datepicker-body table.days td.in-range:not(.select-start):not(.disabled):hover,
.theme-custom-datepicker .bs-datepicker-body table.days span.active:not(.select-start):not(.disabled):hover,
.theme-custom-datepicker .bs-datepicker-body table.days span.in-range:not(.select-start):not(.disabled):hover {
  background: var(--cor-secundaria);
}
/* Background range de seleção de datas */
.theme-custom-datepicker .bs-datepicker-body table.days td.active:not(.select-start):not(.disabled):before,
.theme-custom-datepicker .bs-datepicker-body table.days td.in-range:not(.select-start):not(.disabled):before,
.theme-custom-datepicker .bs-datepicker-body table.days span.active:not(.select-start):not(.disabled):before,
.theme-custom-datepicker .bs-datepicker-body table.days span.in-range:not(.select-start):not(.disabled):before {
  background: var(--background);
}
