/* MENU */

.sidebar{
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 260px;
  background: var(--linear-gradient-0deg);
  z-index: 100;
  transition: all 0.1s linear;
}
.sidebar.close{
  width: 78px;
}
.sidebar .logo-details{
  min-height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
}
.sidebar .logo-details i{
  font-size: 30px;
  color: var(--lw-cor-2);
  height: 46px;
  min-width: 70px;
  text-align: center;
  line-height: 50px;
}
.sidebar .logo-details .logo_name{
  font-size: 22px;
  /* color: var(--lw-cor-p); */
  color: #fff;
  font-weight: 600;
  transition: 0.2s ease;
  transition-delay: 0.1s;
  min-width: 145px;
}
.sidebar.close .logo-details .logo_name{
  transition-delay: 0s;
  opacity: 0;
  pointer-events: none;
}

.toogle-fix {
  font-size: 22px !important;
  color: var(--lw-cor-p) !important;
  font-weight: 600;
  transition: 0.2s ease;
  transition-delay: 0.1s;
  min-width: 25px !important;
  cursor: pointer;
}

.sidebar.close .toogle-fix{
  transition-delay: 0s;
  opacity: 0;
  pointer-events: none;
  display: none;
}


.sidebar .nav-links{
  height: 100vh;
  padding: 0px 0 60px 0;
  overflow: auto !important;
}
.sidebar.close .nav-links{
  overflow: visible;
}
.sidebar .nav-links::-webkit-scrollbar{
  display: none;
}
.sidebar .nav-links li{
  position: relative;
  list-style: none;
  transition: all 0.1s linear;
  cursor: pointer;
}
.sidebar .nav-links li:hover{
  background: var(--lw-cor-2);
  color: var(--hover-cor-primaria) !important;
  transition: all 0.1s linear;
}

.sidebar .nav-links li:hover i {
  color: var(--hover-cor-primaria) !important;
}

.sidebar .nav-links li:hover span {
  color: var(--hover-cor-primaria) !important;
}

.sidebar .nav-links li .iocn-link{
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}
.sidebar.close .nav-links li .iocn-link{
  display: block
}
.sidebar .nav-links li i{
  height: 50px;
  min-width: 70px;
  text-align: center;
  line-height: 50px;
  color: var(--lw-cor-5);
  font-size: 20px;
  cursor: pointer;
  transition: all 0.1s linear;
}
.sidebar .nav-links li.showMenu i.arrow{
  transform: rotate(-180deg);
}
.sidebar.close .nav-links i.arrow{
  display: none;
}
.sidebar .nav-links li a{
  display: flex;
  align-items: center;
  text-decoration: none;
}
.sidebar .nav-links li a .link_name{
  font-size: 16px;
  font-weight: 400;
  color: var(--lw-cor-5);
  transition: all 0.1s linear;
  cursor: pointer;
}

.sidebar .nav-links li div{
  display: flex;
  align-items: center;
  text-decoration: none;
}
.sidebar .nav-links li div .link_name{
  font-size: 16px;
  font-weight: 400;
  color: var(--lw-cor-5);
  transition: all 0.1s linear;
  cursor: pointer;
}

.sidebar.close .nav-links li a .link_name{
  opacity: 0;
  pointer-events: none;
}

.sidebar.close .nav-links li div .link_name{
  opacity: 0;
  pointer-events: none;
}

.sidebar .nav-links li .sub-menu{
  padding: 0px 0px 0px 0px;
  margin-top: 0px;
  background: var(--lw-cor-2);
  /* background: #1d1b31; */
  /* display: none; */
}
.sidebar .nav-links li.showMenu .sub-menu{
  display: block;
}
.sidebar .nav-links li .sub-menu a{
  color: #fff;
  font-size: 15px;
  padding: 5px 0;
  white-space: nowrap;
  opacity: 0.6;
  transition: all 0.1s linear;
}
.sidebar .nav-links li .sub-menu div{
  color: #fff;
  font-size: 15px;
  padding: 5px 0;
  white-space: nowrap;
  opacity: 0.6;
  transition: all 0.1s linear;
}
.sidebar .nav-links li .sub-menu a:hover{
  opacity: 1;
}
.sidebar .nav-links li .sub-menu div:hover{
  opacity: 1;
}
.sidebar.close .nav-links li .sub-menu{
  position: absolute;
  left: 100%;
  top: -10px;
  margin-top: 0;
  padding: 10px 20px;
  border-radius: 0 6px 6px 0;
  opacity: 0;
  display: block;
  pointer-events: none;
  transition: 0s;
}
.sidebar.close .nav-links li:hover .sub-menu{
  top: 0;
  opacity: 1;
  pointer-events: auto;
  transition: all 0.1s linear;
}
.sidebar .nav-links li .sub-menu .link_name{
  display: none;
}
.sidebar.close .nav-links li .sub-menu .link_name{
  font-size: 18px;
  opacity: 1;
  display: block;
}
.sidebar .nav-links li .sub-menu.blank{
  opacity: 1;
  pointer-events: auto;
  padding: 0px 20px 0px 15px;
  opacity: 0;
  pointer-events: none;
}
.sidebar .nav-links li:hover .sub-menu.blank{
  top: 50%;
  transform: translateY(-50%);
  height: 100%;
  display: flex;
  align-items: center;
}
.sidebar .profile-details{
  position: fixed;
  bottom: 0;
  width: 260px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background: var(--linear-gradient-0deg);
  padding: 12px 0;
  transition: all 0.1s linear;
  border-top: 1px solid var(--border-cor);
}
.sidebar.close .profile-details{
  background: none;
}
.sidebar.close .profile-details{
  width: 78px;
}
.sidebar .profile-details .profile-content{
  display: flex;
  align-items: center;
}
.sidebar .profile-details .menu-i{
  height: 40px;
  min-width: 45px;
  object-fit: cover;
  border-radius: 15px;
  margin: 0 15px 0 15px;
  background: var(--cor-danger);
  color: var(--hover-cor-primaria);
  transition: all 0.1s linear;
  font-size: 20px;
  padding: 0;
  display: flex !important;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: row;
}
.sidebar.close .profile-details .menu-i{
  padding: 0px;
}
.sidebar .profile-details .profile_name,
.sidebar .profile-details .job{
  color: var(--lw-cor-5);
  font-size: 18px;
  font-weight: 500;
  white-space: nowrap;
}

.sidebar.close .profile-details .profile_name,
.sidebar.close .profile-details .job{
  display: none;
}

.name-job{
    display: flex;
    flex-direction: column;
    align-items: flex-start !important;
}

.sidebar.close .profile-details .menu-i{
  display: block;

}
.sidebar .profile-details .job{
  font-size: 12px;
}
.home-section{
  display: block;
  position: relative;
  /* background: #0d1b27; */
  /* background: linear-gradient(0deg,#2b2d35 60%,#191b22 100%)!important; */
  /* background: linear-gradient(0deg,#191b22,#191b22)!important; */
  /* min-height: 100vh; */
  left: 260px;
  width: calc(100% - 260px);
  transition: all 0.1s linear;
  padding: 12px 0px;
}
.sidebar.close ~ .home-section{
  left: 78px;
  width: calc(100% - 78px);
  min-height: 100vh;
}

.home-content{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  position: fixed;
  background: var(--linear-gradient--60deg);
  width: 100%;
  height: 60px;
  top: 0;
  padding: 0px 0px 0px 0px;
  box-shadow: var(--box-shadow);
  z-index: 9;
}
.home-section .home-content .bx-menu,
.home-section .home-content .text{
  color: #fff;
  font-size: 32px;
  /* background: -webkit-linear-gradient(315deg, #07f 0%, #3f51b5 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; */
}
.home-section .home-content .bx-menu{
  cursor: pointer;
  margin-right: 15px;
  margin-left: 20px;
}
.home-section .home-content .text{
  font-size: 22px;
  font-weight: 600;
}

@media screen and (max-width: 500px){
  .sidebar{
    width: 240px;
  }
  .sidebar.close{
    width: 0px;
  }
  .sidebar .profile-details{
    width: 240px;
  }
  .sidebar.close .profile-details{
    background: none;
    display: none;
  }
  .sidebar.close .profile-details{
    width: 78px;
  }
  .home-section{
    left: 240px;
    width: calc(100% - 240px);
  }
  .sidebar.close ~ .home-section{
    left: 0px;
    width: calc(100% - 0px);
  }
  .logo-details, .sidebar.close .nav-links{
    overflow: hidden;
  }
  .sidebar .logo-details .logo_name {
    min-width: 135px;
  }
  .sidebar .logo-details i{
    min-width: 69px;
  }
}

.rotate {
  transform: rotate(180deg);
}

.menu-active {
  /* Estilos para a rota ativa */
  background: linear-gradient(90deg, var(--lw-cor-4) 5px, transparent 5px);
  transition: all 0.1s linear;
}

/* FIM MENU */




